import * as React from 'react';
import styled from 'styled-components';
import { Text, MiddleLayout } from 'src/components/Common';
import CustomerBox from './CustomerBox';
import { COLORS } from 'src/style/colors';
import { scrollXRL, scrollXLR } from 'src/style/animations';
import { CustomersList, Subtitle, Root } from './styles';
import { useI18next } from 'gatsby-plugin-react-i18next';

const Image = styled.img`
  width: 150px;
`;

const VaronisImage = styled.img`
  width: 180px;
`;

const CyberArkImage = styled.img`
  width: 180px;
`;

const KlipsImage = styled.img`
  width: 100px;
`;

const DelawveryImage = styled.img`
  width: 180px;
`;

const TikalImage = styled.img`
  width: 130px;
`;

const IntelichainImage = styled.img`
  width: 190px;
`;

const BeAllImage = styled.img`
  width: 180px;
`;

const UnifabrixImage = styled.img`
  width: 210px;
`;

const TpsImage = styled.img`
  width: 50px;
`;

const FiredomeImage = styled.img`
  width: 170px;
`;

const BazImage = styled.img`
  width: 60px;
`;

const SivanImage = styled.img`
  width: 140px;
`;

const EfusionImage = styled.img`
  width: 150px;
`;

const WatchfulImage = styled.img`
  width: 260px;
`;

const InteraiImage = styled.img`
  width: 100px;
`;

const TarnisImage = styled.img`
  width: 200px;
`;

const WaterfallImage = styled.img`
  width: 180px;
`;

const LassoImage = styled.img`
  width: 180px;
`;

const MainWrapper = styled.div`
  display: grid;
  align-content: center;
  gap: 3vw;
  width: 100%;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 3vw;
  margin: auto;
  max-width: 100vw;
`;

const MarqueeGroup = styled('div', {
  shouldForwardProp: (propName) => propName !== 'language',
})`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 3vw;
  justify-content: space-around;
  min-width: 100%;
  animation: ${({ language }) => (language === 'he' ? scrollXRL : scrollXLR)}
    var(--duration) linear infinite;
`;

const MarqueeGroupReverse = styled('div', {
  shouldForwardProp: (propName) => propName !== 'language',
})`
  flex-shrink: 0;
  display: flex;
  gap: 3vw;
  justify-content: space-around;
  min-width: 100%;
  animation: ${({ language }) => (language === 'he' ? scrollXRL : scrollXLR)}
    var(--duration) linear infinite;
  animation-direction: reverse;
  animation-delay: -3s;
`;

const Marquee = styled.div`
  display: flex;
  user-select: none;
  gap: 3vw;
`;

const TOP_LOGOS = [
  <LassoImage src="/images/customers/Lasso.svg" alt="lasso" />,
  <WatchfulImage src="/images/customers/watchful-ai.png" alt="watchful" />,
  <UnifabrixImage src="/images/customers/unifabrix.png" alt="unifabrix" />,
  <KlipsImage src="/images/customers/klips.svg" alt="klips" />,
  <VaronisImage src="/images/varonis-logo.svg" alt="varonis" />,
  <CyberArkImage src="/images/cyberark.svg" alt="cyberark" />,
  <TarnisImage src="/images/taranis.png" alt="taranis" />,
  <BazImage src="/images/customers/Baz_logo_black_Vertical.svg" alt="baz" />,
  <BeAllImage src="/images/be-all.png" alt="beAll" />,
  <SivanImage src="/images/customers/sivan.svg" alt="sivan" />,
  <DelawveryImage src="/images/customers/delawvery.svg" alt="delawvery" />,
  <InteraiImage src="/images/interai.png" alt="interai" />,
  <FiredomeImage src="/images/firedome-logo.svg" alt="firedome" />,
  <EfusionImage src="/images/customers/enfusion.svg" alt="enfusion" />,
  <WaterfallImage src="/images/customers/waterfall.jpeg" alt="waterfall" />,
  <TikalImage src="/images/tikal-logo.png" alt="tikal" />,
  <Image src="/images/validit.jpeg" alt="michlol" />,
  <Image src="/images/avatrade.png" alt="avatrade" />,
  <IntelichainImage src="/images/Intelichain.svg" alt="Intelichain" />,
  <Image src="/images/kando.png" alt="kando" />,
  <Image src="/images/customers/simplify-logo.jpeg" alt="Simplify" />,
  <TpsImage src="/images/TPS.png" alt="tps" />,
];

const BOTTOM_LOGOS = [
  <WaterfallImage src="/images/customers/waterfall.jpeg" alt="waterfall" />,
  <EfusionImage src="/images/customers/enfusion.svg" alt="enfusion" />,
  <IntelichainImage src="/images/Intelichain.svg" alt="Intelichain" />,
  <Image src="/images/avatrade.png" alt="avatrade" />,
  <Image src="/images/validit.jpeg" alt="michlol" />,
  <TikalImage src="/images/tikal-logo.png" alt="tikal" />,
  <Image src="/images/kando.png" alt="kando" />,
  <Image src="/images/customers/simplify-logo.jpeg" alt="Simplify" />,
  <TpsImage src="/images/TPS.png" alt="tps" />,
  <FiredomeImage src="/images/firedome-logo.svg" alt="firedome" />,
  <BeAllImage src="/images/be-all.png" alt="beAll" />,
  <BazImage src="/images/customers/Baz_logo_black_Vertical.svg" alt="baz" />,
  <InteraiImage src="/images/interai.png" alt="interai" />,
  <DelawveryImage src="/images/customers/delawvery.svg" alt="delawvery" />,
  <TarnisImage src="/images/taranis.png" alt="taranis" />,
  <CyberArkImage src="/images/cyberark.svg" alt="cyberark" />,
  <VaronisImage src="/images/varonis-logo.svg" alt="varonis" />,
  <KlipsImage src="/images/customers/klips.svg" alt="klips" />,
  <UnifabrixImage src="/images/customers/unifabrix.png" alt="unifabrix" />,
  <WatchfulImage src="/images/customers/watchful-ai.png" alt="watchful" />,
  <SivanImage src="/images/customers/sivan.svg" alt="sivan" />,
  <LassoImage src="/images/customers/Lasso.svg" alt="lasso" />,
];

const LogosLoop = () => {
  const { language } = useI18next();

  return (
    <Root>
      <MiddleLayout center>
        <Text
          variant="h2"
          color={COLORS.WHITE}
          text="Our Partners To Sucsses"
          animation={{ name: 'flip' }}
          sx={{ fontFamily: 'Poppins' }}
          isExtraBold
        />
        <Subtitle>
          <Text
            variant="body1"
            color={COLORS.WHITE}
            text="Our Customers Description"
            animation={{ name: 'flip' }}
          />
        </Subtitle>
      </MiddleLayout>
      <CustomersList>
        <MainWrapper>
          <Wrapper>
            <Marquee>
              <MarqueeGroup language={language}>
                {TOP_LOGOS.map((logo) => (
                  <CustomerBox key={logo.src}>{logo}</CustomerBox>
                ))}
              </MarqueeGroup>

              <MarqueeGroup language={language}>
                {TOP_LOGOS.map((logo) => (
                  <CustomerBox key={logo.src}>{logo}</CustomerBox>
                ))}
              </MarqueeGroup>
            </Marquee>

            <Marquee>
              <MarqueeGroupReverse language={language}>
                {BOTTOM_LOGOS.map((logo) => (
                  <CustomerBox key={logo.src}>{logo}</CustomerBox>
                ))}
              </MarqueeGroupReverse>

              <MarqueeGroupReverse language={language}>
                {BOTTOM_LOGOS.map((logo) => (
                  <CustomerBox key={logo.src}>{logo}</CustomerBox>
                ))}
              </MarqueeGroupReverse>
            </Marquee>
          </Wrapper>
        </MainWrapper>
      </CustomersList>
    </Root>
  );
};

export default LogosLoop;
