import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text, MiddleLayout, Button } from "src/components/Common";
import SailorSoldierImage from "/src/assets/soldiers/sailor_soldier_p.svg";
import Fade from "react-reveal/Fade";
import { COLORS } from "src/style/colors";
import { between, only } from "styled-breakpoints";
import { useInView } from "react-intersection-observer";
import { Link } from "../../Common/Link";
import { URL } from "src/url";

const Root = styled.section`
  background-color: #f8f8fc;
  padding-top: 20px;
  margin-bottom: 130px;
  height: 370px;

  ${only("mini")} {
    height: auto;
    padding: 25px 0;
    margin-bottom: 80px;
  }

  ${only("mobile")} {
    height: 385px;
  } ;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 50%;

  ${between("mini", "mobile")} {
    width: 100%;
  }

  ${only("laptop")} {
    width: 70%;
  } ;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 20px;

  ${only("mini")} {
    text-align: center;
  } ;
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonWrapper = styled(Link)`
  display: block;
  margin-top: 20px;
  text-decoration: none;

  ${only("mini")} {
    display: flex;
    align-items: center;
    justify-content: center;
  } ;
`;

const SailorSoldier = styled.div`
  width: 230px;
  transform: translateY(-54px);
  transition: all 0.2s;

  ${only("mini")} {
    display: none;
  }

  ${only("mobile")} {
    transform: translateY(-40px);
  } ;
`;

const JoinUsSection = () => {
  const { ref: inViewRef, inView } = useInView();

  const [startType, setStartType] = useState(false);

  useEffect(() => {
    if (inView) setStartType(true);
  }, [inView])

  return (
    <Root ref={inViewRef} key={`JoinUsSection-${startType}`}>
      <MiddleLayout center>
        <Wrapper>
          <Content>
            <HeaderWrapper>
              <Text
                variant="h3"
                color={COLORS.PRIMARY}
                isBold
                text="Join the Appsforce"
                animation={{ name: "typist" }}
              />
            </HeaderWrapper>
            <Fade bottom distance="25px">
              <Texts>
                <Text variant="body1" text="join us 1" />
                <Text variant="body1" text="join us 2" />
                <Text variant="body1" text="join us 3" />
              </Texts>
              <ButtonWrapper to={URL.CAREERS}>
                <Button text="Our open positions" variant="contained" color="secondary" size="large" />
              </ButtonWrapper>
            </Fade>
          </Content>
          <Fade bottom distance="50px">
            <SailorSoldier>
              <SailorSoldierImage />
            </SailorSoldier>
          </Fade>
        </Wrapper>
      </MiddleLayout>
    </Root>
  );
};

export default JoinUsSection;
