import React from 'react';
import styled from 'styled-components';
import { MiddleLayout } from 'src/components/Common';
import { only } from 'styled-breakpoints';
import CustomersRecommendation from './CustomersRecommendation';

const LogoCustomer = styled.img`
  width: 150px;

  ${only('mini')} {
    width: 120px;
  }
`;

const InterAiLogo = styled.img`
  width: 70px;

  ${only('mini')} {
    width: 65px;
  }
`;

const KandoLogo = styled.img`
  width: 90px;

  ${only('mini')} {
    width: 70px;
  }
`;

const ValiditLogo = styled.img`
  width: 100px;

  ${only('mini')} {
    width: 90px;
  }
`;

const FiredomeLogo = styled.img`
  width: 120px;
`;

const CUSTOMERS = [
  {
    text: 'Recommend Text 5',
    image: '/images/recommendations/avi.jpeg',
    renderLogo: (
      <LogoCustomer
        src="/images/customers/recommendation/cyberark.svg"
        alt="cyberark"
      />
    ),
    name: 'Recommend Name 5',
    title: 'Recommend Position 5',
  },
  {
    text: 'Recommend Text Ori Taranis',
    image: '/images/recommendations/ori_taranis.jpeg',
    name: 'Recommend Name Ori Taranis',
    title: 'Recommend Position Ori Taranis',
    renderLogo: (
      <LogoCustomer
        src="/images/customers/recommendation/taranis-light-logo.webp"
        alt="cyberark"
      />
    ),
  },
  {
    text: 'Recommend Text 6',
    image: '/images/recommendations/etan_interai.jpeg',
    name: 'Recommend Name 6',
    title: 'Recommend Position 6',
    renderLogo: (
      <InterAiLogo
        src="/images/customers/recommendation/interai.png"
        alt="kando"
      />
    ),
  },
  {
    text: 'Recommend Text Yossi Penias',
    image: '/images/recommendations/yossi.jpeg',
    name: 'Recommend Name Yossi Penias',
    title: 'Recommend Position Yossi Penias',
    renderLogo: (
      <ValiditLogo
        src="/images/customers/recommendation/validit.png"
        alt="validit"
      />
    ),
  },
  {
    text: 'Recommend Text 4',
    image: '/images/recommendations/nadav.jpeg',
    name: 'Recommend Name 4',
    title: 'Recommend Position 4',
    renderLogo: (
      <KandoLogo
        src="/images/customers/recommendation/kando.svg"
        alt="cyberark"
      />
    ),
  },
  {
    text: 'Recommend Text 1',
    image: '/images/recommendations/shakedi.jpeg',
    name: 'Recommend Name 1',
    title: 'Recommend Position 1',
    renderLogo: (
      <FiredomeLogo
        src="/images/customers/recommendation/firedome.svg"
        alt="firedome"
      />
    ),
  },
  {
    text: 'Recommend Text 2',
    image: '/images/recommendations/or.jpeg',
    name: 'Recommend Name 2',
    title: 'Recommend Position 2',
    renderLogo: (
      <FiredomeLogo
        src="/images/customers/recommendation/firedome.svg"
        alt="firedome"
      />
    ),
  },
];

const Root = styled.section`
  padding: 70px 10px 120px 10px;

  ${only('mini')} {
    padding: 0 0 80px 0;
    --swiper-navigation-color: #e0dfdf;
    --swiper-navigation-top-offset: 52%;
    --swiper-navigation-sides-offset: 12px;
    --swiper-navigation-size: 35px;
  }
`;

const CustomersRecommendations = () => {
  return (
    <Root>
      <MiddleLayout>
        <swiper-container
          slides-per-view="1"
          space-between="0"
          loop="true"
          css-mode="true"
          navigation="true"
        >
          {CUSTOMERS.map((customer) => (
            <swiper-slide key={customer.text}>
              <CustomersRecommendation customer={customer} />
            </swiper-slide>
          ))}
        </swiper-container>
      </MiddleLayout>
    </Root>
  );
};

export default CustomersRecommendations;
