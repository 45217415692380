import React from 'react';
import { graphql } from 'gatsby';
import Home from '../components/Home';
import { register } from 'swiper/element/bundle';
register();

const IndexPage = () => <Home />;

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
