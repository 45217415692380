import * as React from "react";
import LogosLoop from "./LogosLoop";
import LogosFlipping from "./LogosFlipping";
import useMediaQuery from "src/style/useMediaQuery";

const CustomersSection = () => {
  const mediaQuery = useMediaQuery();
  return mediaQuery.isMobileOrMini ? <LogosFlipping /> : <LogosLoop />;
};

export default CustomersSection; 
