import React, { useRef, useEffect, useCallback, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { MiddleLayout, Text } from 'src/components/Common';
import useMediaQuery from 'src/style/useMediaQuery';
import ServiceData from './ServiceData';
import { only, between } from 'styled-breakpoints';
import { URL } from 'src/url';
import { COLORS } from 'src/style/colors';

const fadeIn = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`;

const Section = styled.section``;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin: 100px 0;

  ${between('mini', 'mobile')} {
    margin: 80px 0 0 0;
  } ;
`;

const LeftSide = styled.div`
  margin-top: 60px;
  flex-basis: 50%;

  ${between('mini', 'mobile')} {
    margin-top: -50px;
    flex-basis: 100%;
  } ;
`;

const RightSide = styled.div`
  flex-basis: 50%;
  position: relative;
`;

const StickyImage = styled.div`
  position: sticky;
  top: calc(25%);
  width: 100%;
`;

const Image = styled.img`
  max-width: 500px;
  animation: ${fadeIn} 1s ease-out both;

  ${only('laptop')} {
    max-width: 100%;
  } ;
`;

const HeaderWrapper = styled.div`
  margin-top: 90px;
  text-align: center;

  ${only('mini')} {
    margin-top: 50px;
  } ;
`;

const ServciesRows = () => {
  const rootRef = useRef();
  const stickyImageRef = useRef();
  const [imageNumber, setImageNumber] = useState(0);
  const mediaQuery = useMediaQuery();

  const onScroll = useCallback(() => {
    if (stickyImageRef.current) {
      const { offsetTop } = stickyImageRef.current;
      switch (true) {
        case offsetTop < 225:
          setImageNumber(0);
          break;
        case offsetTop >= 225 && offsetTop < 675:
          setImageNumber(1);
          break;
        case offsetTop >= 675 && offsetTop < 1175:
          setImageNumber(2);
          break;
        default:
          setImageNumber(3);
          break;
      }
    }
  }, [stickyImageRef.current, rootRef.current]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <Section>
      <MiddleLayout>
        <HeaderWrapper>
          <Text
            variant="h1"
            component="h2"
            isExtraBold
            text="Our Serivces"
            animation={{ name: 'flip' }}
            sx={{ fontFamily: 'Poppins' }}
          />
        </HeaderWrapper>
        <Root ref={rootRef}>
          <LeftSide>
            <ServiceData
              header="Front End Development"
              description="Front End Development Description"
              image="/images/frontend.png"
              currentService={imageNumber === 0}
              animationStart="left"
              link={URL.FRONT_END_SERVICE}
            />
            <ServiceData
              header="Fullstack End to End"
              description="Fullstack End to End Description"
              image="/images/fullstack.png"
              currentService={imageNumber === 1}
              animationStart="right"
              link={URL.FULL_STACK_SERVICE}
            />
            <ServiceData
              header="Mobile Development"
              description="Mobile Development Description"
              image="/images/uiux.png"
              currentService={imageNumber === 2}
              animationStart="right"
              link={URL.MOBILE_SERVICE}
            />
            <ServiceData
              header="SaaS Web Applications Consulting"
              description="SaaS Web Applications Consulting Description"
              image="/images/consultation.svg"
              currentService={imageNumber === 3}
              animationStart="left"
              link={URL.SAAS_CONSULTING_SERVICE}
            />
          </LeftSide>
          {!mediaQuery.isMobileOrMini && (
            <RightSide>
              <StickyImage ref={stickyImageRef}>
                {imageNumber === 0 && (
                  <Image src="/images/frontend.png" alt="frontend" />
                )}
                {imageNumber === 1 && (
                  <Image src="/images/fullstack.png" alt="fullstack" />
                )}
                {imageNumber === 2 && (
                  <Image src="/images/uiux.png" alt="uiux" />
                )}
                {imageNumber === 3 && (
                  <Image src="/images/consultation.svg" alt="consultation" />
                )}
              </StickyImage>
            </RightSide>
          )}
        </Root>
      </MiddleLayout>
    </Section>
  );
};

export default ServciesRows;
