import * as React from "react";
import styled, { keyframes } from "styled-components";
import ReactSoldierImage from '/src/assets/soldiers/react_soldier_parachute.svg';
import NodeSoldierImage from '/src/assets/soldiers/node_soldier_parachute.svg';
// import HtmlSoldierImage from '/src/assets/soldiers/html_soldier_parachute.svg';
import PythonSoldierImage from '/src/assets/soldiers/python_soldier_parachute.svg';
import VueSoldierImage from '/src/assets/soldiers/vue_soldier_parachute.svg';
import AngularSoldierImage from 'src/assets/soldiers/angular_soldier_parachute.svg';
import { only, between } from 'styled-breakpoints';

const moveReactSymbol = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360px);
  }
`;

const reactAnimation = keyframes`
  0% {
    transform: translate(100px, -80vh) rotate(10deg);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const vueAnimation = keyframes`
  0% {
    transform: translateY(-80vh) rotate(-20deg);
  }
  100% {
    transform: translateY(0);
  }
`;

const parachuteClear = keyframes`
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const htmlAnimation = keyframes`
  0% {
    transform: translate(-40vw, -80vh) rotate(-50deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
`;

const nodeAnimation = keyframes`
  0% {
    transform: translateY(-80vh) rotate(5deg);
  }
  100% {
    transform: translateY(0);
  }
`;

const pythonAnimation = keyframes`
  0% {
    transform: translate(40vw,-80vh) rotate(60deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
`;

const Root = styled.div`
  height: auto;
  display: flex;
  position: relative;
  align-items: baseline;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
  width: 100%;
  height: 42vh;
  min-height: 350px;

  ${only('mobile')} {
    min-height: auto;
  };

  ${only('mini')} {
    height: calc(50vw - 50px);
    min-height: auto;
  };
`;

const Soldier = styled.div`
  width: 520px;
  position: absolute;
  bottom: -35px;

  ${only('mini')} {
    width: 53%;
    bottom: calc(-9px - 1vw);
  };

  ${only('mobile')} {
    width: 400px;
    bottom: calc(-17px - 1vw);
  };

  ${only('laptop')} {
    width: 480px;
  };
`;

const ReactSoldier = styled(Soldier)`
  animation: ${reactAnimation} 4.2s ease-in-out;

  #react-symbol {
    transform-origin: 50% 14%;
    transform: rotate(-373deg);
    animation: ${moveReactSymbol} 10s ease-in-out infinite;
  }

  #react_parachute_1, #react_parachute_2 {
    animation: ${parachuteClear} 4.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    opacity: 0;
  }
`;

const VueSoldier = styled(Soldier)`
  left: 8%;
  animation: ${vueAnimation} 3.35s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 0.2s;

  ${only('mini')} {
    left: 3%;
  };

  ${between('mobile', 'laptop')} {
    left: 20px;
  };

  ${only('desktop')} {
    left: 5%;
  };

  #vue_parachute {
    animation: ${parachuteClear} 2.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    opacity: 0;
  }
`;

const AngularSoldier = styled(Soldier)`
  right: 8%;
  animation: ${nodeAnimation} 3.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  ${only('mini')} {
    right: 3.3%;
  };

  ${between('mobile', 'laptop')} {
    right: 20px;
  };

  ${only('desktop')} {
    right: 5%;
  };

  #angular_parachute {
    animation: ${parachuteClear} 2.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    opacity: 0;
  }
`;

const PythonSoldier = styled(Soldier)`
    left: -200px;
    animation: ${htmlAnimation} 4s ease-in-out;
    visibility: visible !important;
    animation-fill-mode: forwards;

    ${only('mini')} {
      left: -17%;
    };

    ${only('mobile')} {
      left: -130px;
    };

    #python_parachute {
      animation: ${parachuteClear} 4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      opacity: 0;
    }
`;

const NodeSoldier = styled(Soldier)`
  right: -200px;
  animation: ${pythonAnimation} 3.7s ease-in-out;

  ${only('mini')} {
    right: -18%;
  };

  ${only('mobile')} {
    right: -150px;
  };

  #node_parachute {
    animation: ${parachuteClear} 3.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    opacity: 0;
  }
`;

const Soldiers = () => (
  <Root>
    <PythonSoldier>
      <PythonSoldierImage />
    </PythonSoldier>

    <VueSoldier>
      <VueSoldierImage />
    </VueSoldier>

    <ReactSoldier>
      <ReactSoldierImage />
    </ReactSoldier>

    <AngularSoldier>
      <AngularSoldierImage />
    </AngularSoldier>

    <NodeSoldier>
      <NodeSoldierImage />
    </NodeSoldier>
  </Root>
);

export default Soldiers;
