import styled from 'styled-components';
import { only } from 'styled-breakpoints';

export const Root = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--linear-secondary);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 10px;
  text-align: center;
  overflow: hidden;
  margin-top: -10px;
  z-index: 10;

  ${only('mini')} {
    padding: 50px 10px;
  }
`;

export const Subtitle = styled.div`
  margin-top: 25px;
`;

export const CustomersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
`;
