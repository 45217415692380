import React, { useState, useEffect } from "react";
import { Text } from 'src/components/Common';
import styled, { css } from "styled-components";
import useMediaQuery from 'src/style/useMediaQuery';
import { between } from 'styled-breakpoints';
import { Link, ConditionalWrapper } from 'src/components/Common'
import { fadeInUp } from 'src/style/animations';
import { useInView } from 'react-intersection-observer';

const Root = styled.div`
  margin: 20px 0 250px 0;
  cursor: pointer;

  &:last-child {
    margin-bottom: 50px;
  };

  ${between('mini', 'mobile')} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0 100px 0;
  };
`;

const Content = styled.div`
  color: var(--black);

 ${between('mini', 'mobile')} {
    width: 100%;
    text-align: center;
 };
`;

const SubTitle = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100%;
`;

const Wrap = styled.div`
  visibility: hidden;

  ${props => props.show && css`
    visibility: visible;
    ${fadeInUp};
  `};
`;

const ServiceData = ({ header, description, image, currentService, link }) => {
  const [show, setShow] = useState(false);
  const { ref: inViewRef, inView } = useInView();
  const mediaQuery = useMediaQuery();

  useEffect(() => {
    if (inView) {
      setShow(true);
    }
  }, [inView])

  const wrapSlider = (children) => (
    <Wrap ref={inViewRef} key={`ServiceData-${header}-${show}`} show={show}>
      {children}
    </Wrap>
  );

  return (
    <ConditionalWrapper wrap={wrapSlider} condition={mediaQuery.isMobileOrMini}>
      <Root>
        <Link to={link}>
          <Content>
            {mediaQuery.isMobileOrMini && <Image src={image} alt={image} />}
            <Text
              color="darkl"
              activeAnimation={!mediaQuery.isMobileOrMini && currentService}
              withAnimation
              variant="h2"
              isBold
              whiteSpace={mediaQuery.isMobileOrMini ? 'pre-line' : 'pre'}
              text={header}
            />
            <SubTitle>
              <Text variant="body1" isLight isBlock text={description} />
            </SubTitle>
          </Content>
        </Link>
      </Root>
    </ConditionalWrapper>
  )
}

export default ServiceData
