import React from 'react';
import styled, { keyframes } from 'styled-components';
import { between, up, only } from 'styled-breakpoints';
import { Text, MiddleLayout } from 'src/components/Common';
import Soldiers from './Soldiers';
import { textFocusIn, slideInBottom } from 'src/style/animations';
import { COLORS } from 'src/style/colors';

const enterAnimation = keyframes`
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Root = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 55px;
  overflow: hidden;
  text-align: center;
  overflow: hidden;

  ${between('mini', 'mobile')} {
    padding-top: 20px;
  }
`;

const Header = styled.span`
  ${textFocusIn};
  animation-delay: 3s;
  line-height: 7rem;

  ${between('mini', 'mobile')} {
    animation-delay: 2.5s;
  }

  ${only('mini')} {
    line-height: 3.2rem !important;
  }

  ${only('mobile')} {
    line-height: 5rem !important;
  }

  ${only('laptop')} {
    line-height: 6.1rem !important;
  }

  ${only('desktop')} {
    line-height: 6.1rem !important;
  }
`;

const Content = styled.div`
  ${up('laptop')} {
    animation: ${enterAnimation} 3s ease-out both;
  }

  ${between('mini', 'mobile')} {
    animation: ${enterAnimation} 2.5s ease-out both;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  max-width: 100%;
  margin-top: 20px;
  ${slideInBottom};
  animation-delay: 3.8s;

  ${between('mini', 'mobile')} {
    animation-delay: 3.3s;
  }
`;

const SpecialText = styled(Text)`
  font-size: 5.9rem !important;

  ${only('mini')} {
    font-size: 2.4rem !important;
  }

  ${only('mobile')} {
    font-size: 4.2rem !important;
  }

  ${only('laptop')} {
    font-size: 5.1rem !important;
  }

  ${only('desktop')} {
    font-size: 5.4rem !important;
  }
`;

const TopSection = () => (
  <Root>
    <MiddleLayout center>
      <Content>
        <Header>
          <Text
            color="darkl"
            variant="h1"
            text="Main page header"
            sx={{ fontFamily: 'Poppins' }}
            isExtraBold
          />
          <div>
            <Text
              color="darkl"
              variant="h1"
              text="Main page header 2"
              isExtraBold
              component="span"
              sx={{ fontFamily: 'Poppins' }}
            />
            <SpecialText
              color={COLORS.SECONDARY}
              variant="h1"
              text="Main page header 3"
              isExtraBold
              component="span"
              sx={{ fontFamily: 'Poppins' }}
            />
          </div>
        </Header>
        <TextWrapper>
          <Text
            text="top section header"
            variant="h6"
            component="p"
            whiteSpace="pre-line"
            isLight
          />
        </TextWrapper>
      </Content>
      <Soldiers />
    </MiddleLayout>
  </Root>
);

export default TopSection;
