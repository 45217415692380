import * as React from 'react';
import styled from 'styled-components';
import { only } from 'styled-breakpoints';

const Root = styled.div`
  width: 270px;
  height: 180px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 11px 27px #00000029;
  transition: all 0.2s;
  overflow: hidden;

  ${only('mini')} {
    width: 140px;
    height: 140px;
  }

  ${only('mobile')} {
    width: 175px;
    height: 175px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const CustomerBox = ({ children }) => <Root>{children}</Root>;

export default CustomerBox;
