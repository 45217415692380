import React, { useRef } from 'react';
import styled from 'styled-components';
import AirPlane from 'src/assets/air_plane-02.svg';
import { Text, MiddleLayout } from 'src/components/Common';
import Slide from 'react-reveal/Slide';
import { fly, float } from 'src/style/animations';
import { only } from 'styled-breakpoints';
import { useInView } from 'react-intersection-observer';

const Root = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  background-image: url('/images/WAVE_SERVICES.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 680px;
  z-index: 10;
  overflow: hidden;

  ${only('mini')} {
    padding: 30px 0;
  } ;
`;

const Content = styled.div`
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  text-align: center;
`;

const PaperPlaneWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  width: 300px;
  height: 300px;
  ${fly};

  ${only('mini')} {
    width: 200px;
    height: 200px;
    top: 15px;
  }

  svg {
    max-width: 100%;
  }
`;

const MiddleLayoutStyled = styled(MiddleLayout)`
  position: relative;
`;

const ArrowDownIcon = styled.span`
  ${float};
  border: solid var(--white);
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 9px;
`;

const Services = () => {
  const ref = useRef();
  const { ref: inViewRef, inView } = useInView();

  return (
    <Root>
      <MiddleLayoutStyled>
        <div ref={inViewRef} key={`service-section-${inView}`}>
          <Content ref={ref}>
            <Text
              text="We do it like a unit"
              variant="h2"
              color="white"
              isBold
              animation={{ name: 'flip' }}
            />
            <Slide bottom>
              <Text
                variant="body1"
                isLight
                color="white"
                text="We do it like a unit description"
                whiteSpace="break-spaces"
              />
            </Slide>
          </Content>
        </div>
      </MiddleLayoutStyled>
      <PaperPlaneWrapper>
        <AirPlane />
      </PaperPlaneWrapper>
    </Root>
  );
};

export default Services;
