import * as React from 'react';
import styled from 'styled-components';
import { Text, MiddleLayout } from 'src/components/Common';
import CustomerBox from './CustomerBox';
import Flip from 'react-reveal/Flip';
import { COLORS } from 'src/style/colors';
import { CustomersList, Subtitle, Root } from './styles';

const Image = styled.img`
  max-width: 110%;
`;

const VaronisImage = styled.img`
  width: 100px;
`;

const KlipsImage = styled.img`
  max-width: 90%;
`;

const BazImage = styled.img`
  max-width: 50%;
`;

const IntelichainImage = styled.img`
  max-width: 120%;
`;

const TpsImage = styled.img`
  max-width: 50%;
`;

const FiredomeImage = styled.img`
  max-width: 120%;
`;

const EnfusionImage = styled.img`
  max-width: 120%;
`;

const WatchfulImage = styled.img`
  width: 250px;
`;

const InteraiImage = styled.img`
  max-width: 90%;
`;

const TarnisImage = styled.img`
  max-width: 150%;
`;

const WaterfallImage = styled.img`
  width: 120px;
`;

const LassoImage = styled.img`
  width: 105px;
`;

const DelawveryImage = styled.img`
  max-width: 120%;
`;

const UnifabrixImage = styled.img`
  max-width: 170%;
`;

const LogosFlipping = () => {
  return (
    <Root>
      <MiddleLayout center>
        <Text
          variant="h2"
          color={COLORS.WHITE}
          isBold
          text="Our Partners To Sucsses"
          animation={{ name: 'flip' }}
        />
        <Subtitle>
          <Text
            variant="body1"
            color={COLORS.WHITE}
            text="Our Customers Description"
            animation={{ name: 'flip' }}
          />
        </Subtitle>
        <CustomersList>
          <Flip top duration={1500}>
            <CustomerBox>
              <EnfusionImage
                src="/images/customers/enfusion.svg"
                alt="enfusion"
              />
            </CustomerBox>
          </Flip>
          <Flip top delay={200} duration={1500}>
            <CustomerBox>
              <VaronisImage src="/images/varonis-logo.svg" alt="varonis" />
            </CustomerBox>
          </Flip>
          <Flip bottom delay={400} duration={1500}>
            <CustomerBox>
              <Image src="/images/cyberark.svg" alt="cyberark" />
            </CustomerBox>
          </Flip>
          <Flip bottom delay={600} duration={1500}>
            <CustomerBox>
              <TarnisImage src="/images/taranis.png" alt="taranis" />
            </CustomerBox>
          </Flip>
          <Flip top delay={800} duration={1500}>
            <CustomerBox>
              <WaterfallImage
                src="/images/customers/waterfall.jpeg"
                alt="waterfall"
              />
            </CustomerBox>
          </Flip>
          <Flip bottom delay={1000} duration={1500}>
            <CustomerBox>
              <BazImage src="/images/customers/Baz_logo_black_Vertical.svg" alt="baz" />
            </CustomerBox>
          </Flip>
          <Flip top delay={1200} duration={1500}>
            <CustomerBox>
              <InteraiImage src="/images/interai.png" alt="interai" />
            </CustomerBox>
          </Flip>
          <Flip top delay={1400} duration={1500}>
            <CustomerBox>
              <Image src="/images/avatrade.png" alt="avatrade" />
            </CustomerBox>
          </Flip>
          <Flip top delay={1600} duration={1500}>
            <CustomerBox>
              <WatchfulImage
                src="/images/customers/watchful-ai.png"
                alt="watchful"
              />
            </CustomerBox>
          </Flip>
          <Flip top delay={1800} duration={1500}>
            <CustomerBox>
              <UnifabrixImage
                src="/images/customers/unifabrix.png"
                alt="Intelichain"
              />
            </CustomerBox>
          </Flip>
          <Flip top delay={1800} duration={1500}>
            <CustomerBox>
              <KlipsImage src="/images/customers/klips.svg" alt="klips" />
            </CustomerBox>
          </Flip>
          <Flip top delay={2000} duration={1500}>
            <CustomerBox>
              <FiredomeImage src="/images/firedome-logo.svg" alt="firedome" />
            </CustomerBox>
          </Flip>
          <Flip top delay={2200} duration={1500}>
            <CustomerBox>
              <Image src="/images/be-all.png" alt="be-all" />
            </CustomerBox>
          </Flip>
          <Flip top delay={2400} duration={1500}>
            <CustomerBox>
              <Image src="/images/validit.jpeg" alt="michlol" />
            </CustomerBox>
          </Flip>
          <Flip top delay={2600} duration={1500}>
            <CustomerBox>
              <LassoImage src="/images/customers/sivan.svg" alt="sivan" />
            </CustomerBox>
          </Flip>
          <Flip top delay={2800} duration={1500}>
            <CustomerBox>
              <LassoImage src="/images/customers/Lasso.svg" alt="lasso" />
            </CustomerBox>
          </Flip>
          <Flip top delay={3000} duration={1500}>
            <CustomerBox>
              <DelawveryImage src="/images/customers/delawvery.svg" alt="delawvery" />
            </CustomerBox>
          </Flip>
          <Flip top delay={3200} duration={1500}>
            <CustomerBox>
              <Image src="/images/michlol-logo.png" alt="michlol" />
            </CustomerBox>
          </Flip>
          <Flip top delay={3400} duration={1500}>
            <CustomerBox>
              <Image src="/images/tikal-logo.png" alt="tikal" />
            </CustomerBox>
          </Flip>
          <Flip top delay={3600} duration={1500}>
            <CustomerBox>
            <Image src="/images/kando.png" alt="kando" />
            </CustomerBox>
          </Flip>
          <Flip top delay={3800} duration={1500}>
            <CustomerBox>
                <IntelichainImage
                  src="/images/Intelichain.svg"
                  alt="Intelichain"
               />
            </CustomerBox>
          </Flip>
          <Flip top delay={4000} duration={1500}>
            <CustomerBox>
              <TpsImage src="/images/TPS.png" alt="tps" />
            </CustomerBox>
          </Flip>
        </CustomersList>
      </MiddleLayout>
    </Root>
  );
};

export default LogosFlipping;
