import * as React from 'react';
import styled from 'styled-components';
import TopSection from './TopSection';
import ServicesSection from './ServicesSection';
import ServciesRows from './ServicesSection/ServciesRows';
import CustomersSection from './CustomersSection';
import CustomersRecommendations from './CustomersSection/CustomersRecommendations';
import JoinUsSection from './JoinUsSection';
import { SEO } from '../Common';
import { organizationSchema } from '../../schema/organization';

const Root = styled.main``;

const IndexPage = () => {
  return (
    <Root>
      <SEO
        title="Home page title"
        description="Home page description"
        image="https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/appsforce_banner.jpg?alt=media&token=5125e882-7d08-4642-8c37-0f90e4fb055a"
        organizationSchema={organizationSchema}
      />
      <TopSection />
      <CustomersSection />
      <ServciesRows />
      <ServicesSection />
      <CustomersRecommendations />
      <JoinUsSection />
    </Root>
  );
};

export default IndexPage;
