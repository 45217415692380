export const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Appsforce",
  "url": "https://appsforce.io/",
  "logo": "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/favicon1.png?alt=media&token=1e414ce8-2122-4d2b-9745-a596338c52e8",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "אינשטיין 1",
    "addressLocality": "הרצליה",
    "postalCode": "4659070",
    "addressCountry": "ישראל"
  },
  "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "צרו קשר",
    "telephone": "972-52-3954991"
  },
  "sameAs": [
    "https://www.linkedin.com/company/appsforceio"
  ]
};