import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import styled, { css } from 'styled-components';
import { Text } from 'src/components/Common';
import { COLORS } from 'src/style/colors';
import { fadeIn, talking } from 'src/style/animations';
import { only, between } from 'styled-breakpoints';

const parser = new UAParser(); // you need to pass the user-agent for nodejs

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 50px;

  ${between('mini', 'mobile')} {
    flex-direction: column;
    margin: 0 10px;
    padding: 0;
  }
`;

const CustomerBubble = styled.div`
  position: relative;
  border-radius: 35px;
  padding: 0px 45px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--linear-secondary);
  ${fadeIn};
  height: 400px;

  ${between('mini', 'mobile')} {
    flex-direction: column;
    padding: 50px 60px;
    text-align: center;
    min-height: 645px;
    height: fit-content;
  }

  ${only('mini')} {
    padding: 30px 30px;
  }

  ${only('laptop')} {
    height: 450px;
  }
`;

const CustomerDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  gap: 20px;
`;

const CustomerTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${only('mini')} {
    text-align: start;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 40px;
  text-align: start;
`;

const Image = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;

  ${only('mini')} {
    height: 85px;
    width: 85px;
  }
`;

const ImageWrapper = styled.div`
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 3px solid #9b00ff !important;

  ${(props) =>
    props.$isChrome &&
    css`
      ${talking}
    `};

  ${only('mini')} {
    height: 85px;
    width: 85px;
  }
`;

const BottomLine = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  ${only('mini')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    text-align: start;
  }
`;

const Spectator = styled.div`
  width: 2px;
  height: 32px;
  background-color: #fff;

  ${only('mini')} {
    display: none;
  }
`;

const QuoteOpenImage = styled.img`
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 90px;

  ${only('mini')} {
    width: 70px;
  }
`;

const QuoteCloseImage = styled.img`
  position: absolute;
  z-index: 1000;
  right: 0;
  bottom: 0;
  width: 90px;

  ${only('mini')} {
    width: 70px;
  }
`;

const StyledText = styled(Text)`
  ${only('mini')} {
    font-size: 0.9rem !important;
  }
`;

const CustomersRecommendation = ({ customer }) => {
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    setIsChrome(parser.getBrowser()?.name === 'Chrome');
  }, []);

  return (
    <Root>
      <QuoteOpenImage src="/images/quote-open.svg" alt="quote-open" />
      <QuoteCloseImage src="/images/quote-close.svg" alt="quote-close" />
      <CustomerBubble>
        <CustomerDetails>
          <ImageWrapper $isChrome={isChrome}>
            <Image src={customer.image} alt="customer" />
          </ImageWrapper>
          <CustomerTexts>
            <Text
              color={COLORS.WHITE}
              variant="h6"
              isExtraBold
              text={customer.name}
              component="div"
              sx={{ fontFamily: 'Poppins' }}
            />
            <BottomLine>
              <StyledText
                color={COLORS.WHITE}
                variant="h6"
                text={customer.title}
                component="div"
              />
              <Spectator />
              {customer.renderLogo}
            </BottomLine>
          </CustomerTexts>
        </CustomerDetails>

        <TextWrapper>
          <Text
            color={COLORS.WHITE}
            variant="body2"
            text={customer.text}
            sx={{ lineHeight: '30px' }}
          />
        </TextWrapper>
      </CustomerBubble>
    </Root>
  );
};
export default CustomersRecommendation;
